// extracted by mini-css-extract-plugin
export var backgroundCircles = "NotFoundMain-module--backgroundCircles--91062";
export var heroBackgroundCircleLeft = "NotFoundMain-module--heroBackgroundCircleLeft--c9319";
export var heroBackgroundInnerCircleLeft = "NotFoundMain-module--heroBackgroundInnerCircleLeft--47eba";
export var heroBackgroundInnerCircleLeftSecond = "NotFoundMain-module--heroBackgroundInnerCircleLeftSecond--c3612";
export var heroWhiteBlurCirce = "NotFoundMain-module--heroWhiteBlurCirce--7dddc";
export var heroWhiteBlurCirceSecond = "NotFoundMain-module--heroWhiteBlurCirceSecond--b5924";
export var notFoundBody = "NotFoundMain-module--notFoundBody--6e363";
export var notFoundDesc = "NotFoundMain-module--notFoundDesc--98b26";
export var notFoundImage = "NotFoundMain-module--notFoundImage--360d1";
export var notFoundTitle = "NotFoundMain-module--notFoundTitle--41f0b";
export var notFoundWrapper = "NotFoundMain-module--notFoundWrapper--97737";
export var returnToHomeButton = "NotFoundMain-module--returnToHomeButton--a1b1f";